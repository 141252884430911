import React, { PropsWithChildren } from "react";
import { TooltipCell } from "@app/common";
import * as Styled from "./index";

interface Props extends PropsWithChildren {
  isCustom?: boolean;
  maxWidth?: number;
}

const TableCell = ({ maxWidth, isCustom, children }: Props) => {
  if (isCustom) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <Styled.Cell maxWidth={maxWidth}>
      {typeof children === "string" ? (
        <TooltipCell text={children} />
      ) : (
        children
      )}
    </Styled.Cell>
  );
};

export default TableCell;
