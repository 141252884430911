import React, { memo, PropsWithChildren } from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";

interface Props extends PropsWithChildren {}

export enum Colors {
  white = "#FFF",
  black = "#000",
  light = "#FAFAFA",
  dark = "#1C2246",
  disabled = "#EEEEEE",
  orange = "#ED4A3F",
  orangeLight = "#F99335",
  gray = "#ACACAC",
  grayDark = "#636463",
  grayLight = "#D8D8D8",
  green = "#5BCD71",
  greenLight = "rgba(91,205,113,0.33)",
  greenDark = "#00A468",
  red = "#DA616C",
  redLight = "#EEB1B5",
  blue = "#4F8BFF",
  blueLight = "#C7D3EA",
  yellow = "#FFB84E",
  yellowAccent = "#FFCF00",
  yellowLight = "#FCF1DA",
  blueGreen = "#17A2B8",
  background = "#F5F6F8",
}

const theme = {
  fontFamily: '"Inter", sans-serif',
  color: {
    dark: Colors.dark,
    black: Colors.black,
    light: Colors.light,
    disabled: Colors.disabled,
    orange: Colors.orange,
    orangeLight: Colors.orangeLight,
    gray: Colors.gray,
    grayDark: Colors.grayDark,
    grayLight: Colors.grayLight,
    white: Colors.white,
    green: Colors.green,
    greenLight: Colors.greenLight,
    greenDark: Colors.greenDark,
    red: Colors.red,
    redLight: Colors.redLight,
    blue: Colors.blue,
    blueLight: Colors.blueLight,
    yellow: Colors.yellow,
    yellowAccent: Colors.yellowAccent,
    yellowLight: Colors.yellowLight,
    blueGreen: Colors.blueGreen,
    background: Colors.background,
  },
};

function ThemeProvider(props: Props) {
  const { children } = props;

  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>;
}

export default memo(ThemeProvider);
