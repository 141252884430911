import { excludeVAT, MONTH_UNIT_ID } from "./index";
import { getDaysInMonth } from "date-fns";
import {
  ActDetail,
  ActVehicleItemV2,
  NdsShort,
  VehicleJournalForActV2,
} from "@app/models";

export const actTableLabelsBase = [
  "",
  "№",
  "Дата",
  "Смена",
  "Время работы",
  "Вид техники",
  "Характеристика",
  "Вид услуги",
  "Гос номер",
  "Данные линии",
  "Моточасы GPS",
  "Длительность\nсмены по GPS",
  "Ед. изм.",
  "Цена аренды",
  "Данные к оплате",
  "Сумма",
  "Сумма НДС",
  "Итого к оплате",
];

export const actTableLabels = [...actTableLabelsBase, "Комментарий", ""];

export const actTableLabelsCreate = [
  ...actTableLabelsBase,
  "Комментарий",
  "",
  "",
];

export const actTableLabelsRejected = [
  ...actTableLabelsBase,
  "Причина\nотклонения",
  "Комментарий",
  "",
  "",
];

export const actTableLabelsTask = [
  ...actTableLabelsBase,
  "Согласовать",
  "Причина\nотклонения",
  "Комментарий",
  "",
];

export const getVehicleItemsTotal = (
  items: ActVehicleItemV2[] | null,
  ndsRate: NdsShort["rate"]
) => {
  return (items || []).map((item) => {
    let sum = item.toPay * (item.price || 0);

    if (item.unitDto?.id === MONTH_UNIT_ID) {
      const days = getDaysInMonth(new Date(item.workDate));
      const toPayPerDay = days ? (item.price || 0) / days : 0;
      sum = item.toPay * toPayPerDay;
    }

    item.summa = sum;
    item.summaNds = excludeVAT(sum, ndsRate);
    item.total = sum;

    return {
      ...item,
    };
  });
};

export const getActVehicleFiles = (actVehicles: VehicleJournalForActV2[]) => {
  const itemsMap = actVehicles.flatMap((item) => item.items);
  return itemsMap.flatMap((item) => item?.files ?? []);
};

export const getActVehicleDetails = (
  actVehicleGroupDto: VehicleJournalForActV2[],
  vehicleDetails: ActDetail["vehicleDetails"]
) => {
  return actVehicleGroupDto.map((journal) => {
    const { items } = journal;
    if (items && items.length) {
      const [vehicleItem] = items;
      const details: ActVehicleItemV2[] =
        vehicleDetails?.filter(
          (item) => item.vehicleId === vehicleItem.vehicleId
        ) ?? [];

      const sorted = details.sort(
        (a, b) =>
          new Date(a.workDate).getTime() - new Date(b.workDate).getTime()
      );
      const formattedDetails = sorted.map((detail) => ({
        ...detail,
        startTime: vehicleItem.startTime,
        endTime: vehicleItem.endTime,
        vehicleTypeDto: vehicleItem.vehicleTypeDto,
        nomenclature: vehicleItem.nomenclature,
        comment: vehicleItem.comment,
        unitDto: {
          ...vehicleItem.unitDto,
          name: "месяц (день)",
        },
      }));
      return {
        ...journal,
        items: [...items, ...formattedDetails],
      };
    }
    return journal;
  });
};
