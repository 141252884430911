import React, { memo, ReactNode, useCallback, useMemo, useState } from "react";
import { Button, Modal, ModalProps, Table, TextField } from "@app/components";
import { useNotification } from "@app/providers";
import { rejectTask } from "@app/api";
import {
  currencyFormat,
  dateFormat,
  getAxiosErrorMessage,
  joinValues,
  NIGHT_SHIFT_ID,
  roundToTwo,
} from "@app/helpers";
import { ActVehicleItemV2 } from "@app/models";
import styled from "styled-components";
import { WrappedCell } from "../ActOfServicesDetail/components/styled";
import { CustomCell } from "../ActTaskDetail/components/TaskAOS";

interface Props extends ModalProps {
  taskId?: string;
  rejectedVehicles: ActVehicleItemV2[];
  onRejectedVehiclesChange: (vehicles: ActVehicleItemV2[]) => void;
  onClose: (saved?: boolean) => void;
}

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const tableLabels = [
  "Дата",
  "Смена",
  "Вид техники",
  "Характеристика",
  "Гос номер",
  "Данные к оплате",
  "Итого к оплате",
  "Причина отклонения",
];

interface InputProps {
  initValue: string | null;
  onBlur: (value: string) => void;
}

function CommentInput({ initValue, onBlur }: InputProps) {
  const [state, setState] = useState<string>(initValue ?? "");

  const _onChange = (value: string) => setState(value);
  const _onBlur = () => onBlur(state);

  return (
    <CustomCell width={200}>
      <TextField value={state} onChange={_onChange} onBlur={_onBlur} />
    </CustomCell>
  );
}

const ModalActTaskReject = (props: Props) => {
  const {
    onClose,
    taskId,
    rejectedVehicles,
    onRejectedVehiclesChange,
    ...restProps
  } = props;
  const { showNotification } = useNotification();
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const onRejectConfirm = useCallback(async () => {
    if (!taskId) {
      return;
    }

    const rejectedActVehicles = rejectedVehicles.map((veh) => ({
      actVehicleId: veh.actVehicleId,
      comment: veh.comment,
    }));

    const body = {
      id: taskId,
      comment,
      rejectedActVehicles,
    };

    setLoading(true);
    rejectTask(body)
      .then(() => {
        showNotification({
          variant: "success",
          message: "Отклонен",
        });
        setLoading(false);
        onClose(true);
      })
      .catch((e) => {
        setLoading(false);
        showNotification({
          message: getAxiosErrorMessage(e),
          variant: "error",
        });
      });
  }, [comment, onClose, rejectedVehicles, showNotification, taskId]);

  const actions = useMemo(() => {
    const returnActions: ReactNode[] = [];
    returnActions.push(
      <Button
        text="Подтвердить отклонение"
        onClick={onRejectConfirm}
        showLoader={loading}
      />
    );
    return returnActions;
  }, [loading, onRejectConfirm]);

  const onCommentChange = useCallback(
    (index: number) => (value: string) => {
      rejectedVehicles[index] = {
        ...rejectedVehicles[index],
        comment: value,
      };
      onRejectedVehiclesChange([...rejectedVehicles]);
    },
    [onRejectedVehiclesChange, rejectedVehicles]
  );

  const mapTableData = useCallback(
    (item: ActVehicleItemV2, index: number) => {
      return [
        dateFormat(item.workDate, "dd.MM.yyyy"),
        item.shiftTypeDto?.id === NIGHT_SHIFT_ID ? "Ночь" : "День",
        <WrappedCell>
          {joinValues([item.vehicleTypeDto, item.nomenclature])}
        </WrappedCell>,
        item.characteristic?.name,
        item.govNumber,
        item.toPay,
        item.summa ? currencyFormat(roundToTwo(item.summa)) : "—",
        <CommentInput
          initValue={item.comment}
          onBlur={onCommentChange(index)}
        />,
      ];
    },
    [onCommentChange]
  );

  const tableData = useMemo<(string | ReactNode)[][]>(
    () => rejectedVehicles.map(mapTableData),
    [rejectedVehicles, mapTableData]
  );

  const onMainCommentChange = (value: string) => {
    setComment(value);
  };

  return (
    <Modal actions={actions} size={"large"} onClose={onClose} {...restProps}>
      <StyledContent>
        <TextField
          label="Общий комментарий"
          placeholder="Укажите причину отклонения"
          value={comment}
          name="comment"
          onChange={onMainCommentChange}
        />

        <Table labels={tableLabels} data={tableData} onClick={() => {}} />
      </StyledContent>
    </Modal>
  );
};

export default memo(ModalActTaskReject);
