import instance from "./instance";
import { APIResponse } from "./interfaces";
import { Confirmer } from "@app/models";

export interface SignInData {
  username: string;
  password: string;
  asPartner: boolean;
}

export interface SignUpData {
  firstName: string;
  lastName: string;
  middleName: string;
  iin: string;
  phone: string;
  email: string;
  password: string;
  confirmPassword: string;
  userRegistration: UserRegistrationType;
}

export interface ChangePasswordData {
  phone: string;
  password: string;
  confirmPassword: string;
}

export enum UserRegistrationType {
  IsPartner = 1,
  IsCustomer = 2,
}

interface SignInResponse {
  token: string;
  refreshToken: string;
}

export interface UserCounts {
  contractCount: number;
  actCount: number;
  memoCount: number;
  allCount: number;
  adhesionContractCount: number;
}

export function signIn(data: Partial<SignInData>): Promise<SignInResponse> {
  return instance(false)
    .post("/account/token", data)
    .then((response) => response.data);
}

export function refreshTokens(data: {
  refreshToken: string;
}): Promise<SignInResponse> {
  return instance(false)
    .post("/account/refresh-token", data)
    .then((response) => response.data);
}

export function getCode(phone: string): Promise<APIResponse> {
  return instance(false)
    .get(`/account/${phone}`)
    .then((response) => response.data);
}

export function verifyCode(phone: string, code: string): Promise<APIResponse> {
  return instance(false)
    .get(`/account/${phone}/verify/${code}`)
    .then((response) => response.data);
}

export function accountRegistration(
  data: Partial<SignUpData>
): Promise<boolean> {
  return instance(false)
    .post("/account/registration", data)
    .then((response) => response.data);
}

export function checkPhone(phone: string): Promise<boolean> {
  return instance(false)
    .get(`/account/check/phone/${phone}`)
    .then((response) => response.data)
    .catch(() => false);
}

export function postVerificationPhoneCode(number: string): Promise<boolean> {
  return instance(false)
    .post("/verification/phoneNumber/code", null, {
      params: { PhoneNumber: number },
    })
    .then((response) => response.data)
    .catch(() => false);
}

export function getVerificationPhoneCode(
  number: string,
  code: string
): Promise<boolean> {
  return instance(false)
    .get("/verification/phoneNumber/code", {
      params: {
        PhoneNumber: number,
        Code: code,
      },
    })
    .then((response) => response.data)
    .catch(() => false);
}

export function postVerificationEmail(email: string): Promise<APIResponse> {
  return instance(false)
    .post("/verification/email/code", null, {
      params: { Email: email },
    })
    .then((response) => response.data)
    .catch(() => false);
}

export function getVerificationEmail(
  email: string,
  code: string
): Promise<APIResponse> {
  return instance(false)
    .get("/verification/email/code", {
      params: { Email: email, Code: code },
    })
    .then((response) => response.data)
    .catch(() => false);
}

export function changePasswordByEmailVerification(
  email: string,
  password: string,
  verificationCode: string
): Promise<APIResponse> {
  return instance(false)
    .put("account/password/byVerificationCode", {
      email: email,
      password: password,
      verificationCode: verificationCode,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response?.data ?? error;
    });
}

export function changePassword(
  data: Partial<ChangePasswordData>
): Promise<boolean> {
  return instance()
    .post("/account/change/password", data)
    .then((response) => response.data);
}

export function addDevice(deviceId: string): Promise<APIResponse> {
  return instance()
    .post("/account/device", {
      deviceId,
    })
    .then((response) => response.data);
}

export function deleteDevice(deviceId: string): Promise<APIResponse> {
  return instance()
    .delete("/account/device", {
      data: {
        deviceId,
      },
    })
    .then((response) => response.data);
}

export function getCountByUser(): Promise<APIResponse<UserCounts>> {
  return instance()
    .get("/task/active/count-by-user")
    .then((response) => response.data);
}

export function getDispatchers(): Promise<APIResponse<Confirmer[]>> {
  return instance()
    .get("/account/by-dispatcher")
    .then((response) => response.data);
}

export function getAccountRegion(): Promise<APIResponse<any>> {
  return instance()
    .get("/account/regions")
    .then((response) => response.data);
}

export function postAccountRegion(
  regionIds: string[]
): Promise<APIResponse<any>> {
  return instance()
    .post("/account/regions", { regionIds })
    .then((response) => response.data);
}
