import instance from "./instance";
import {
  DocumentType,
  Task,
  TaskRedirectRequest,
  Adhesion,
  ConstructiveRequest,
} from "@app/models";
import { APIResponse, PageableParams, PageableResponse } from "./interfaces";

export interface TaskPageableParams extends PageableParams {
  forModuleId?: DocumentType;
  isUnfinished?: boolean;
}

export interface SignAdhesionContractRequest {
  taskId: string;
  signedFile: string;
}

export interface ApproveTaskRequest {
  id: string;
  comment: string;
  constructives: ConstructiveRequest[];
}

interface RejectedActVehicle {
  actVehicleId: string;
  comment: string | null;
}

export interface RejectTaskRequest {
  id: string;
  comment: string;
  rejectedActVehicles: RejectedActVehicle[];
}

export function getTasks(
  params: TaskPageableParams
): Promise<PageableResponse<Task>> {
  return instance()
    .get("/task", {
      params,
    })
    .then((response) => response.data);
}

export function adhesionContractById(
  id: string
): Promise<APIResponse<Adhesion>> {
  return instance()
    .get(`/adhesionContract/${id}`)
    .then((response) => response.data);
}

export function signAdhesionContract(
  data: SignAdhesionContractRequest
): Promise<APIResponse<Adhesion>> {
  return instance()
    .post(`/adhesionContract/sign/`, data)
    .then((response) => response.data);
}

export function getTaskById(id: string): Promise<APIResponse<Task>> {
  return instance()
    .get(`/task/${id}`)
    .then((response) => response.data);
}

export function approveActTask(
  data: ApproveTaskRequest
): Promise<APIResponse<Task>> {
  return instance()
    .put(`/task/act/approve`, data)
    .then((response) => response.data);
}

export function approveTaskContract(
  id: string,
  comment: string
): Promise<APIResponse<Task>> {
  return instance()
    .put(`/task/contract/approve`, { id, comment })
    .then((response) => response.data);
}

export function acquaintedTask(id: string): Promise<APIResponse<Task>> {
  return instance()
    .put(`/task/act/acquainted/${id}`)
    .then((response) => response.data);
}

export function rejectTask(
  data: RejectTaskRequest
): Promise<APIResponse<Task>> {
  return instance()
    .put(`/task/act/reject`, data)
    .then((response) => response.data);
}

export function rejectTaskContract(
  id: string,
  comment: string
): Promise<APIResponse<Task>> {
  return instance()
    .put(`/task/contract/reject`, { id, comment })
    .then((response) => response.data);
}

export function redirectTask(
  body: TaskRedirectRequest
): Promise<APIResponse<Task>> {
  return instance()
    .put(`/task/act/redirect`, body)
    .then((response) => response.data);
}

export function redirectContractTask(
  id: string,
  body: TaskRedirectRequest
): Promise<APIResponse<Task>> {
  return instance()
    .put(`/task/contract/redirect`, body)
    .then((response) => response.data);
}
