import React, { FC, memo } from "react";
import { Button, Stepper } from "@app/components";
import { ButtonRow, StepperWrapper, StyledStepper } from "./styled";
import { aosCreateSteps } from "../constants";

type Props = {
  currentStep: number;
  loading: boolean;
  isEdit: boolean;
  approveDisabled: boolean;
  onPrevClick: () => void;
  onNextClick: () => void;
  onSaveClick: () => void;
  onEditClick: () => void;
};

const StepperRow: FC<Props> = ({
  currentStep,
  loading,
  approveDisabled,
  isEdit,
  onPrevClick,
  onNextClick,
  onSaveClick,
  onEditClick,
}) => {
  return (
    <StepperWrapper>
      <StyledStepper>
        <Stepper
          steps={aosCreateSteps}
          disabled={false}
          currentStep={currentStep}
        />
      </StyledStepper>
      <ButtonRow>
        {isEdit && (
          <Button
            text="Исправить"
            variant="newDesign"
            showLoader={loading}
            onClick={onEditClick}
          />
        )}
        <Button
          text="Назад"
          variant="text"
          disabled={currentStep <= 1}
          showLoader={loading}
          onClick={onPrevClick}
        />
        <Button
          text="Сохранить"
          variant="outlined"
          showLoader={loading}
          onClick={onSaveClick}
        />
        <Button
          text={currentStep === 3 ? "Отправить на согласование" : "Далее"}
          showLoader={loading}
          disabled={approveDisabled}
          onClick={onNextClick}
        ></Button>
      </ButtonRow>
    </StepperWrapper>
  );
};

export default memo(StepperRow);
