import { dateFormat, getFullName, objectToOptions } from "@app/helpers";
import { FilterParams } from "./types";
import { vehicleTypes } from "./constants";
import {
  ActDetail,
  ActSignerDTO,
  ActVehicleItem,
  ActVehicleItemV2,
  UserTaskTypeName,
  VehicleItem,
  VehicleJournalForActV2,
} from "@app/models";

export const getAosCreateRequest = (filterParams: FilterParams) => {
  return {
    organizationId: filterParams.organization?.value,
    projectId: filterParams.project?.value,
    partnerId: filterParams.partner?.value,
    isLifting: filterParams.vehicleType?.value
      ? filterParams.vehicleType?.value === "true"
      : null,
    startDate: filterParams.startDate
      ? dateFormat(filterParams.startDate, "yyyy-MM-dd")
      : null,
    endDate: filterParams.endDate
      ? dateFormat(filterParams.endDate, "yyyy-MM-dd")
      : null,
    contractId: filterParams.contract?.value,
  };
};

export const getAosCreateFilterParams = (act: ActDetail): FilterParams => {
  return {
    organization: objectToOptions(act.company),
    project: objectToOptions(act.project),
    partner: objectToOptions(act.partner),
    vehicleType: vehicleTypes[act.isLifting ? 1 : 0],
    startDate: new Date(act.startWorkDate),
    endDate: new Date(act.endWorkDate),
    contract: objectToOptions(act.contract),
    nds: objectToOptions(act.nds),
    currency: objectToOptions(act.currency),
  };
};

export const convertActSignersForSelect = (actSigners: ActSignerDTO[]) => {
  return actSigners.map((item: ActSignerDTO) => ({
    ...item,
    user: {
      label: getFullName(item.userShort),
      value: item.userShort?.id || null,
    },
    task: {
      label: UserTaskTypeName[item.taskTypeId],
      value: item.taskTypeId,
    },
  }));
};

export const prepareVehicleItems = (vehicles: ActVehicleItem[]) => {
  return vehicles.map((item) => ({
    actVehicleId: item.actVehicleId,
    toPay: item.toPay || 0,
    total: item.total || 0,
  }));
};

export const prepareVehicles = (actVehicles: VehicleJournalForActV2[]) => {
  return actVehicles.reduce((acc: VehicleItem[], cur) => {
    // Убираем технику без id, добавленную для ед.изм. Месяц.
    const filtered: ActVehicleItemV2[] =
      cur.items?.filter((item) => !!item.id) ?? [];

    const arr =
      filtered.map((item) => ({
        vehicleJurnalId: item.id,
        toPay: item.toPay || 0,
        total: item.total || 0,
      })) || [];
    return [...acc, ...arr];
  }, []);
};

export const prepareEditVehicles = (actVehicles: VehicleJournalForActV2[]) => {
  return actVehicles.reduce((acc: ActVehicleItem[], cur) => {
    // Убираем технику без id, добавленную для ед.изм. Месяц.
    const filtered: ActVehicleItemV2[] =
      cur.items?.filter((item) => !!item.id) ?? [];

    const arr: ActVehicleItem[] = prepareVehicleItems(filtered);
    return [...acc, ...arr];
  }, []);
};
