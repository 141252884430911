import React, { memo, ReactNode, useCallback, useMemo, useState } from "react";
import {
  BaseButton,
  Button,
  Table,
  TextField,
  TimePicker,
  Tooltip,
} from "@app/components";
import {
  ActDetail,
  ActListItem,
  ActStatus,
  ActVehicleItemV2,
  KIPVehicleDetails,
  VehicleJournalForActV2,
} from "@app/models";
import { eachDayOfInterval } from "date-fns";
import {
  actTableLabelsCreate,
  actTableLabelsRejected,
  currencyFormat,
  dateFormat,
  excludeVAT,
  joinValues,
  MONTH_UNIT_ID,
  NIGHT_SHIFT_ID,
  roundToTwo,
} from "@app/helpers";
import {
  IconChevronDown24,
  IconChevronUp24,
  IconDay24,
  IconNight24,
  IconWarningTriangle24,
  IconPlay24,
  IconTrash24,
} from "@app/icons";
import styled from "styled-components";
import { FilterParams } from "../types";
import { theme } from "styled-tools";
import { getPayData, getVehiclesByActIdV2 } from "@app/api";
import { ModalKIPVehicleTrack } from "@app/modals";
import TableRowFile from "../../../common/TableRowFile";

interface Props {
  filterParams?: FilterParams;
  actData: ActListItem | null;
  actDetail: ActDetail | undefined;
  actVehicles: VehicleJournalForActV2[];
  onListChange: (
    vehicles: VehicleJournalForActV2[],
    reformat?: boolean,
    deletedItems?: ActVehicleItemV2[]
  ) => void;
}

const TableContainer = styled.div`
  margin: 16px 24px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
`;

const ParentCell = styled.td`
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  padding: 12px;
  width: 1%;
  white-space: nowrap;
  text-align: left;
  border-top: 1px solid ${theme("color.grayLight")};
  font-family: ${theme("fontFamily")};
  color: ${theme("color.dark")};
  background-color: ${theme("color.blueLight")};
`;

const ChildCell = styled.td<{ rejected?: boolean }>`
  font-size: 14px;
  line-height: 16px;
  padding: 12px;
  width: 1%;
  white-space: nowrap;
  text-align: left;
  border-top: 1px solid ${theme("color.grayLight")};
  background-color: ${(props) =>
    theme(props.rejected ? "color.redLight" : "color.white")};
`;

const CustomCell = styled.div`
  display: flex;
  width: 150px;
  p {
    font-size: 14px;
    font-style: italic;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:empty {
      &:after {
        content: "—";
      }
    }
  }
`;

const WrappedCell = styled.div`
  max-width: 340px;
  font-size: 14px;
  line-height: 16px;
  white-space: normal;
`;

const StyledPlayBtn = styled.button`
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background-color: ${theme("color.green")};
  cursor: pointer;
  border-radius: 50%;
`;

function Step2(props: Props) {
  const { onListChange, actData, actDetail, actVehicles, filterParams } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [collapsedRows, setCollapsedRows] = useState<number[]>([]);
  const [selectedAct, setSelectedAct] = useState<ActVehicleItemV2>();
  const [vehicleDetails, setVehicleDetails] =
    useState<KIPVehicleDetails | null>(null);

  const isActRejected = actData?.status === ActStatus.Rejected;

  const updateVehicleList = useCallback(
    (actId: string) => {
      setLoading(true);

      getVehiclesByActIdV2(actId)
        .then((res) => {
          setLoading(false);
          onListChange(res.data, true);
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [onListChange]
  );

  const onClickToFill = useCallback(() => {
    if (actData?.id) {
      updateVehicleList(actData?.id);
    }
  }, [actData?.id, updateVehicleList]);

  const onClickToPay = useCallback(() => {
    if (actData?.id) {
      const vehicleJurnalIds = actVehicles.reduce((acc: string[], cur) => {
        return [...acc, ...(cur.ids || [])];
      }, []);

      setLoading(true);
      getPayData({
        actId: actData.id,
        vehicleJurnalIds,
      }).then(() => {
        setLoading(false);
        updateVehicleList(actData.id);
      });
    }
  }, [actData, actVehicles, updateVehicleList]);

  const tableLabels = useMemo(
    () => (isActRejected ? actTableLabelsRejected : actTableLabelsCreate),
    [isActRejected]
  );

  const getCellSum = useCallback(
    (parentIndex: number, index: number, key: keyof ActVehicleItemV2) => {
      const vItems = actVehicles[parentIndex].items;
      if (vItems && vItems.length) {
        const item = vItems[index];
        let sum = item.toPay * (item.price || 0);

        if (
          item.unitDto?.id === MONTH_UNIT_ID &&
          filterParams?.startDate &&
          filterParams?.endDate
        ) {
          const days = eachDayOfInterval({
            start: filterParams.startDate,
            end: filterParams.endDate,
          });
          const toPayPerDay = item.price / days.length;
          sum = item.toPay * toPayPerDay;
        }

        if (key === "summaNds") {
          return excludeVAT(sum, filterParams!.nds?.item?.rate);
        } else if (key === "total") {
          return sum;
        }
        return sum;
      }
      return 0;
    },
    [actVehicles, filterParams]
  );

  const onPayChange = useCallback(
    (
      value: any,
      item: ActVehicleItemV2,
      parentIndex: number,
      index: number
    ) => {
      const formattedValue =
        typeof value === "string" ? value.replace(/,/g, ".") : value;
      item.toPay = parseFloat(formattedValue);
      let sum = item.toPay * (item.price || 0);

      if (
        item.unitDto?.id === MONTH_UNIT_ID &&
        filterParams?.startDate &&
        filterParams?.endDate
      ) {
        const days = eachDayOfInterval({
          start: filterParams.startDate,
          end: filterParams.endDate,
        });
        const toPayPerDay = item.price / days.length;
        sum = item.toPay * toPayPerDay;
      }
      item.summa = sum;
      item.summaNds = excludeVAT(sum, filterParams!.nds?.item?.rate);
      item.total = sum;

      const _vehicles = actVehicles[parentIndex].items;
      if (_vehicles) {
        _vehicles.splice(index, 1, item);
      }
      onListChange([...actVehicles]);
    },
    [actVehicles, filterParams, onListChange]
  );

  const onPlayClick = useCallback(
    (act: ActVehicleItemV2) => () => {
      setSelectedAct(act);
      setVehicleDetails({
        workStartDateTime: act.startTime,
        workEndDateTime: act.endTime,
      } as KIPVehicleDetails);
    },
    []
  );

  const onWorkPerformedChange = useCallback(
    (
      value: any,
      item: ActVehicleItemV2,
      parentIndex: number,
      index: number
    ) => {
      const _vehicles = actVehicles[parentIndex].items;
      if (_vehicles) {
        item.workPerformed = value;
        _vehicles.splice(index, 1, item);
      }
      onListChange([...actVehicles]);
    },
    [actVehicles, onListChange]
  );

  const onCloseTrackModal = useCallback(() => {
    setVehicleDetails(null);
  }, []);

  // Table cells
  const getMapTableData = useCallback(
    (array: ActVehicleItemV2[], parentIndex: number) => {
      return array.map((item, index) => {
        if (!item) {
          return [];
        }

        const props = {
          rejected: item.isRejected,
        };

        return [
          <ChildCell {...props}>
            <StyledPlayBtn onClick={onPlayClick(item)}>
              <IconPlay24 />
            </StyledPlayBtn>
          </ChildCell>,
          <ChildCell {...props}>{index + 1}</ChildCell>,
          <ChildCell {...props}>
            {dateFormat(item.workDate, "dd.MM.yyyy")}
          </ChildCell>,
          <ChildCell {...props}>
            {item.shiftTypeDto?.id === NIGHT_SHIFT_ID ? (
              <IconNight24 />
            ) : (
              <IconDay24 />
            )}
          </ChildCell>,
          <ChildCell {...props}>
            {item.startTime
              ? joinValues([
                  dateFormat(item.startTime, "HH:mm"),
                  dateFormat(item.endTime, "HH:mm"),
                ])
              : ""}
          </ChildCell>,
          <ChildCell {...props}>
            <WrappedCell>
              {joinValues([item.vehicleTypeDto, item.nomenclature])}
            </WrappedCell>
          </ChildCell>,
          <ChildCell {...props}>{item.characteristic?.name}</ChildCell>,
          <ChildCell {...props}>{item.serviceType?.name}</ChildCell>,
          <ChildCell {...props}>{item.govNumber}</ChildCell>,
          <ChildCell {...props}>
            {item.id ? (
              item.workPerformed
            ) : (
              <TimePicker
                value={`${item.workPerformed ?? ""}`}
                onChange={(val) =>
                  onWorkPerformedChange(val, item, parentIndex, index)
                }
              />
            )}
          </ChildCell>,
          <ChildCell {...props}>{item.hoursGps || 0}</ChildCell>,
          <ChildCell {...props}>{item.shiftDuration}</ChildCell>,
          <ChildCell {...props}>{item.unitDto?.name}</ChildCell>,
          <ChildCell {...props}>{currencyFormat(item.price)}</ChildCell>,
          <ChildCell {...props}>
            {item.id ? (
              <TextField
                value={`${item.toPay || 0}`}
                type="number"
                width="130px"
                inputProps={{
                  min: 0,
                }}
                disabled={isActRejected}
                onChange={(val) => onPayChange(val, item, parentIndex, index)}
              />
            ) : (
              "—"
            )}
          </ChildCell>,
          <ChildCell {...props}>
            {currencyFormat(
              roundToTwo(getCellSum(parentIndex, index, "summa"))
            )}
          </ChildCell>,
          <ChildCell {...props}>
            {currencyFormat(
              roundToTwo(getCellSum(parentIndex, index, "summaNds"))
            )}
          </ChildCell>,
          <ChildCell {...props}>
            {currencyFormat(
              roundToTwo(getCellSum(parentIndex, index, "total"))
            )}
          </ChildCell>,
          isActRejected && (
            <ChildCell {...props}>
              {item.rejectComment && (
                <Tooltip content={item.rejectComment}>
                  <IconWarningTriangle24 />
                </Tooltip>
              )}
            </ChildCell>
          ),
          <ChildCell {...props}>
            <CustomCell title={item.comment || ""}>
              <p>{item.comment}</p>
            </CustomCell>
          </ChildCell>,
          <ChildCell {...props}>
            <TableRowFile files={item.files} />
          </ChildCell>,
          <ChildCell {...props}>
            <BaseButton
              color={"color.orange"}
              title={"Удалить"}
              // Не удалять строку Месяца, т.к. есть расшифровка по дням
              disabled={item.unitDto?.id === MONTH_UNIT_ID && !!item.id}
              onClick={() => {
                const _vehicles = actVehicles[parentIndex].items;
                let deleted: ActVehicleItemV2[] = [];
                if (_vehicles) {
                  deleted = _vehicles.splice(index, 1);
                }
                onListChange([...actVehicles], false, deleted);
              }}
            >
              <IconTrash24 />
            </BaseButton>
          </ChildCell>,
        ].filter(Boolean);
      });
    },
    [
      onPlayClick,
      isActRejected,
      getCellSum,
      onWorkPerformedChange,
      onPayChange,
      actVehicles,
      onListChange,
    ]
  );

  const reduceTableData = useCallback(
    (item: VehicleJournalForActV2, index: number) => {
      if (item && item.items) {
        return getMapTableData(item.items, index);
      }

      return [];
    },
    [getMapTableData]
  );

  // Parent Row
  const reduceItemsSum = useCallback(
    (vehicleItems: ActVehicleItemV2[] | null, key: keyof ActVehicleItemV2) => {
      if (!vehicleItems) return 0;

      return vehicleItems.reduce((sum, item) => {
        return sum + roundToTwo((item[key] || 0) as number);
      }, 0);
    },
    []
  );

  // Extra row
  const reduceSum = useCallback(
    (key: keyof ActVehicleItemV2) => {
      const summa = actVehicles.reduce((acc, cur) => {
        return acc + reduceItemsSum(cur.items, key);
      }, 0);
      return currencyFormat(roundToTwo(summa));
    },
    [actVehicles, reduceItemsSum]
  );

  // Parent Row
  const mapTableCollapsedData = useCallback(
    (item: VehicleJournalForActV2) => {
      if (!item) {
        return [];
      }

      return [
        <ParentCell>
          <IconChevronDown24 />
        </ParentCell>,
        <ParentCell colSpan={3} style={{ minWidth: "170px" }}>
          Итого по ед. изм.
        </ParentCell>,
        <ParentCell />,
        <ParentCell>{item.vehicleTypeName}</ParentCell>,
        <ParentCell>{item.characteristic?.name}</ParentCell>,
        <ParentCell />,
        <ParentCell>{item.govNumber}</ParentCell>,
        <ParentCell>{item.totalWorkPerformed}</ParentCell>,
        <ParentCell>{item.totalHoursGps}</ParentCell>,
        <ParentCell>{item.totalShiftDuration}</ParentCell>,
        <ParentCell>{item.unitDto?.name}</ParentCell>,
        <ParentCell />,
        <ParentCell>{reduceItemsSum(item.items, "toPay")}</ParentCell>,
        <ParentCell>{`${currencyFormat(
          roundToTwo(reduceItemsSum(item.items, "summa"))
        )}`}</ParentCell>,
        <ParentCell>{`${currencyFormat(
          roundToTwo(reduceItemsSum(item.items, "summaNds"))
        )}`}</ParentCell>,
        <ParentCell>{`${currencyFormat(
          roundToTwo(reduceItemsSum(item.items, "total"))
        )}`}</ParentCell>,
        isActRejected && <ParentCell />,
        <ParentCell />,
        <ParentCell />,
        <ParentCell />,
      ];
    },
    [isActRejected, reduceItemsSum]
  );

  const tableData = useMemo<(string | ReactNode)[][]>(
    () => actVehicles.map(mapTableCollapsedData),
    [actVehicles, mapTableCollapsedData]
  );

  const tableDataChildren = useMemo<(string | ReactNode)[][][]>(
    () => actVehicles.map(reduceTableData),
    [actVehicles, reduceTableData]
  );

  const onParentRowExpand = useCallback(() => {
    if (collapsedRows.length) {
      setCollapsedRows([]);
    } else {
      setCollapsedRows(tableData.map((_, i) => i));
    }
  }, [collapsedRows.length, tableData]);

  const tableExtraRow = useMemo<(string | ReactNode)[]>(
    () =>
      tableLabels.map((_, index) => {
        if (index === 0) {
          return (
            <BaseButton onClick={onParentRowExpand}>
              {collapsedRows.length ? (
                <IconChevronDown24 />
              ) : (
                <IconChevronUp24 />
              )}
            </BaseButton>
          );
        } else if (index === 1) {
          return "Итого";
        } else if (index === 15) {
          return reduceSum("summa");
        } else if (index === 16) {
          return reduceSum("summaNds");
        } else if (index === 17) {
          return reduceSum("total");
        } else if (index === 13) {
          return (
            <Button
              text="Заполнить"
              size="small"
              variant="contained"
              showLoader={loading}
              disabled={!actDetail?.actVehicleGroupDto?.length}
              onClick={onClickToFill}
            />
          );
        } else if (index === 14) {
          return (
            <Button
              text="К оплате"
              size="small"
              variant="contained"
              showLoader={loading}
              disabled={!actData || !actDetail?.actVehicleGroupDto?.length}
              onClick={onClickToPay}
            />
          );
        }
        return "";
      }),
    [
      actData,
      actDetail?.actVehicleGroupDto?.length,
      collapsedRows.length,
      loading,
      onClickToFill,
      onClickToPay,
      onParentRowExpand,
      reduceSum,
      tableLabels,
    ]
  );

  return (
    <div>
      {tableData.length ? (
        <TableContainer>
          <Table
            labels={tableLabels}
            collapsedData={tableData}
            nodeData={tableDataChildren}
            extraRow={tableExtraRow}
            collapsedRows={collapsedRows}
            customCells={true}
            onClick={() => {}}
            onRowCollapse={(val) => setCollapsedRows(val)}
          />
        </TableContainer>
      ) : (
        <p>Список техник пуст</p>
      )}
      {selectedAct && (
        <ModalKIPVehicleTrack
          open={!!vehicleDetails}
          projectId={actData?.project.id}
          vehicleDetails={vehicleDetails}
          vehicleId={selectedAct.vehicleId}
          vehicleGovNumber={selectedAct.govNumber}
          onClose={onCloseTrackModal}
        />
      )}
    </div>
  );
}

export default memo(Step2);
