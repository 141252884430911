import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, Modal, ModalProps, Table, TextField } from "@app/components";
import { useNotification } from "@app/providers";
import { APIResponse, editActVehicles } from "@app/api";
import {
  currencyFormat,
  dateFormat,
  excludeVAT,
  getAxiosErrorMessage,
  joinValues,
  MONTH_UNIT_ID,
  NIGHT_SHIFT_ID,
  roundToTwo,
} from "@app/helpers";
import { ActVehicleItemV2 } from "@app/models";
import styled from "styled-components";
import { WrappedCell } from "../ActOfServicesDetail/components/styled";
import { eachDayOfInterval } from "date-fns";
import { FilterParams } from "../ActOfServicesCreate";
import { prepareVehicleItems } from "../ActOfServicesCreate/helpers";
import { AxiosError } from "axios";
import { theme } from "styled-tools";

interface Props extends ModalProps {
  actId: string | undefined;
  rejectedVehicles: ActVehicleItemV2[];
  filterParams?: FilterParams;
  sendToApprove: () => void;
  onClose: () => void;
}

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid ${theme("color.gray")};
`;

const tableLabels = [
  "Дата",
  "Смена",
  "Вид техники",
  "Характеристика",
  "Гос номер",
  "Цена аренды",
  "Данные к оплате",
  "Сумма",
  "Сумма НДС",
  "Итого к оплате",
  "Причина отклонения",
];

interface InputProps {
  initValue: string;
  onBlur: (value: string) => void;
}

function ToPayInput({ initValue, onBlur }: InputProps) {
  const [state, setState] = useState<string>(initValue);

  const _onChange = (value: string) => setState(value);
  const _onBlur = () => onBlur(state);

  return (
    <TextField
      value={state}
      type="number"
      width="130px"
      inputProps={{
        min: 0,
      }}
      onChange={_onChange}
      onBlur={_onBlur}
    />
  );
}

const ModalActVehiclesEdit = (props: Props) => {
  const {
    onClose,
    actId,
    rejectedVehicles: propsRejectedVehicles,
    filterParams,
    sendToApprove,
    ...restProps
  } = props;
  const { showNotification } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [rejectedVehicles, setRejectedVehicles] = useState<ActVehicleItemV2[]>(
    []
  );

  useEffect(() => {
    setRejectedVehicles(propsRejectedVehicles);
  }, [propsRejectedVehicles]);

  const onApprove = useCallback(async () => {
    try {
      setLoading(true);
      const vehicles = prepareVehicleItems(rejectedVehicles);
      const body = {
        id: actId!,
        vehicles,
      };
      const { succeeded } = await editActVehicles(body);

      if (succeeded) {
        sendToApprove();
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      showNotification({
        message: getAxiosErrorMessage(err as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [actId, rejectedVehicles, sendToApprove, showNotification]);

  const actions = useMemo(() => {
    const returnActions: ReactNode[] = [];
    returnActions.push(
      <Button
        text="Отправить на согласование"
        onClick={onApprove}
        showLoader={loading}
      />
    );
    return returnActions;
  }, [loading, onApprove]);

  const onPayChange = useCallback(
    (index: number) => (value: any) => {
      const row = rejectedVehicles[index];
      const formattedValue =
        typeof value === "string" ? value.replace(/,/g, ".") : value;
      const toPay = parseFloat(formattedValue);
      let summa = toPay * (row.price || 0);
      if (
        row.unitDto?.id === MONTH_UNIT_ID &&
        filterParams?.startDate &&
        filterParams?.endDate
      ) {
        const days = eachDayOfInterval({
          start: filterParams.startDate,
          end: filterParams.endDate,
        });
        const toPayPerDay = row.price / days.length;
        summa = toPay * toPayPerDay;
      }
      const summaNds = excludeVAT(summa, filterParams?.nds?.item?.rate);

      rejectedVehicles[index] = {
        ...rejectedVehicles[index],
        toPay,
        summa,
        summaNds,
      };
      setRejectedVehicles([...rejectedVehicles]);
    },
    [
      filterParams?.endDate,
      filterParams?.nds,
      filterParams?.startDate,
      rejectedVehicles,
    ]
  );

  const mapTableData = useCallback(
    (item: ActVehicleItemV2, index: number) => [
      dateFormat(item.workDate, "dd.MM.yyyy"),
      item.shiftTypeDto?.id === NIGHT_SHIFT_ID ? "Ночь" : "День",
      <WrappedCell>
        {joinValues([item.vehicleTypeDto, item.nomenclature])}
      </WrappedCell>,
      item.characteristic?.name,
      item.govNumber,
      currencyFormat(item.price),
      <ToPayInput
        initValue={`${item.toPay ?? 0}`}
        onBlur={onPayChange(index)}
      />,
      item.summa ? currencyFormat(roundToTwo(item.summa)) : "—",
      item.summaNds ? currencyFormat(roundToTwo(item.summaNds)) : "—",
      item.summa ? currencyFormat(roundToTwo(item.summa)) : "—",
      item.rejectComment,
    ],
    [onPayChange]
  );

  const tableData = useMemo<(string | ReactNode)[][]>(
    () => rejectedVehicles.map(mapTableData),
    [rejectedVehicles, mapTableData]
  );

  const onModalClose = () => {
    onClose();
  };

  return (
    <Modal
      actions={actions}
      size={"large"}
      onClose={onModalClose}
      {...restProps}
    >
      <StyledContent>
        <Table labels={tableLabels} data={tableData} onClick={() => {}} />
      </StyledContent>
    </Modal>
  );
};

export default memo(ModalActVehiclesEdit);
